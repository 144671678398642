import React from 'react';
import { Container } from 'react-bootstrap';
// import { EXTERNAL_LINKS } from '../util/constants';

const Banner = () => (
    <div id="gs-htg-banner">
        <Container className="bg-banner-db text-white text-center p-3" fluid>
            <h4 className="m-0">
                <a href="https://form.typeform.com/to/uxlD3QIt#email_hidden=xxxxx" target="_blank" rel="noopener noreferrer">Apply</a>
                {' '}
                for Hack the Globe 2025!
            </h4>
            <h6>
                Application deadline extended to February 9th, 2025 at 11:59PM EST!
            </h6>
        </Container>
    </div>
);

export default Banner;

// For storage
/* Applications for Hack The Globe are now open!
                {' '}
                <a href={EXTERNAL_LINKS.HACKER_DASH} target="_blank" rel="noopener noreferrer">Apply</a>
                {' '}
                by Feb 7.

        <Container className="bg-banner-db text-white text-center p-3" fluid>
            <h4 className="m-0">
                <a href="https://forms.gle/uTC9PEriCK9ZjaSw6" target="_blank" rel="noopener noreferrer">Apply</a>
                {' '}
                to join Global Spark's team for 2024/25!
            </h4>
            <h6>
                Application deadline extended to April 14th, 2024 at 11:59PM EST!
            </h6>
        </Container>
                */
